<script lang="ts">
import "@cisco-u/button/cu-button.js";

import { defineComponent } from "vue";

import { sendButtonCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  name: "JoinCiscoUBttn",
  props: {
    btntype: {
      type: String,
      default: "primary",
    },
    text: {
      type: String,
      default: "Join Cisco U. Free",
    },
  },
  methods: {
    isUnauth,
    async sendTelemetry() {
      if (this.isUnauth()) {
        await sendButtonCtaTelemetry(this.$route.fullPath, this.text, true);
      }
      window.location.href = window.location.origin + "/login";
    },
  },
});
</script>

<template>
  <cu-button
    :template="btntype"
    href="/login"
    @click.prevent="sendTelemetry()"
    >{{ text }}</cu-button
  >
</template>
