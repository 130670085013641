import { z } from "zod";

import http from "@/services/http";

const progressData = z.object({
  id: z.string().optional(),
  pathGuid: z.string().optional(),
  statusId: z.string().optional(),
  progressPercentage: z.number().optional(),
  completionDate: z.string().nullish(),
  tracks: z
    .array(
      z.object({
        id: z.string(),
        trackGuid: z.string(),
        statusId: z.string(),
        progressPercentage: z.number(),
        completionDate: z.string().nullish(),
      })
    )
    .nullish(),
  courses: z
    .array(
      z.object({
        id: z.string(),
        guid: z.string(),
        title: z.string(),
        type: z.string(),
        statusId: z.string(),
        isProficient: z.string(),
        progressPercentage: z.number(),
        completionDate: z.string().nullish(),
        lastAccessedDate: z.string().nullish(),
      })
    )
    .nullish(),
});

const removeProgressResponse = z.object({
  guid: z.string(),
  type: z.string(),
  status: z.string(),
});

export async function fetch(guid: string) {
  if (!window.env.APOLLO_MIDDLEWARE_API_LOCATION || !guid || guid == "") {
    return;
  }
  const { data } = await http().get(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/learning-path-progresses/${guid}`
  );

  if (!data) {
    return;
  }
  return progressData.parse(data);
}

export async function removeProgress(guid: string, type: string) {
  if (!window.env.APOLLO_MIDDLEWARE_API_LOCATION || !guid || guid == "") {
    return;
  }
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/remove-learning-content`,
    {
      guid,
      type,
    }
  );

  if (!data) {
    return;
  }
  return removeProgressResponse.parse(data);
}
