<script lang="ts">
import { defineComponent } from "vue";

import SanitizeUrl from "@/utils/sanitizeUrl";

export default defineComponent({
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    cleanUrl() {
      return SanitizeUrl(this.url);
    },
  },
});
</script>

<template>
  <a :href="cleanUrl" tabindex="0" rel="noopener">
    <slot />
  </a>
</template>
