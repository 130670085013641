<script lang="ts">
import "swiper/css";
import "swiper/css/navigation";
import "@cisco-u/icons/icons/cu-icon-chevron-right.js";
import "@cisco-u/navigation-buttons/cu-navigation-buttons.js";
import "@cisco-u/pagination/cu-pagination.js";
import "@cisco-u/promo-card/cu-promo-card.js";

import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import theCards from "@/components/contentBits/theCards.vue";
import type { Result } from "@/components/Result";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";
import SkeletonLoader from "@/views/Skeleton/Search/SkeletonLoader.vue";

const learningPathResult = "learning-path-result";
interface PromoResult {
  type: string;
}

// create union type for PromoResult and Result
type ResultUnion = Result | PromoResult;

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    theCards,
    SkeletonLoader,
  },

  props: {
    results: {
      type: Array as PropType<Result[]>,
      default: () => [],
    },
    resultComponent: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    viewAllText: {
      type: String,
      default: "",
    },
    exploreAllText: {
      type: String,
      default: "",
    },
    fetched: {
      type: Boolean,
      default: false,
    },
    topicsString: {
      type: String,
      default: "",
    },
    viewAllTag: {
      type: String,
      default: "",
    },
    viewAllLink: {
      type: Object,
      default: () => ({}),
    },
    hideViewAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: 0,
      showOptions: false,
      focusedItem: 0,
      modules: [Pagination, Mousewheel, Navigation],
      vssWidth: 0,
      swiper: null as any,
      swiperPages: 0 as number,
      swiperIndex: 0 as number,
    };
  },

  watch: {
    swiper(swiper: any) {
      this.storeSwiperPaginationNum(swiper);
    },
    slidesPerGroup() {
      this.swiper?.update();
    },
    results() {
      this.swiper?.update();
    },
  },

  computed: {
    ...mapGetters({
      status: "user/status",
    }),
    isDesktop(): boolean {
      return this.vssWidth >= 1440;
    },
    multiResults(): boolean {
      return this.results?.length > 0 && "results" in this.results[0];
    },
    selectedResults(): ResultUnion[] {
      let returnResults: ResultUnion[] = this.results;
      if (this.multiResults) {
        if (this.resultComponent == learningPathResult) {
          // @ts-ignore
          return returnResults[this.selected]?.tracks;
        }
        // @ts-ignore
        return returnResults[this.selected]["results"];
      }

      if (this.isPromo) {
        // insert promoResult every 5th card
        let promoIndex = 0;
        returnResults = returnResults.reduce((acc: any, result: any) => {
          if (promoIndex % 5 === 0) {
            acc.push({ type: "promo" });
          }
          acc.push(result);
          promoIndex++;
          return acc;
        }, []);
      }
      return returnResults;
    },
    slidesPerGroup: function (): number {
      let rtn = 0;
      switch (this.resultComponent) {
        case learningPathResult:
        case "tutorial-result":
        case "instructor-led-result":
          rtn = this.returnLargeCardGroup();
          break;
        case "cert-card":
        case "videos-card":
        case "pod-cast-card":
        case "event-result":
        case "challenge-result":
        case "practice-exam":
        case "practice-exam-result":
          rtn = this.returnSmallCardGroup();
          break;
      }
      return rtn;
    },
    isExplorePage(): boolean {
      return this.routePath().includes("/explore");
    },
    isCertificationPage(): boolean {
      return this.routePath().includes("/certification");
    },
    isPodcastPage(): boolean {
      return this.routePath().includes("/podcast/");
    },
    isForYouPage(): boolean {
      return this.routePath().includes("/for-you");
    },
    isCommunityLandingPage(): boolean {
      return this.routePath().includes("/communities");
    },
    isMyCommunityPage(): boolean {
      return this.routePath().includes("/my-learning/community");
    },
    noResultsText(): string {
      let start = `There are currently no ${this.exploreAllText}`;
      if (this.isForYouPage) {
        if (!this.fetched) {
          return "Loading recommendations";
        }
        start = start.slice(0, start.length - 1);
        start = `${start} recommendations`;
      }
      return `${start}`;
    },
    isPromo(): boolean {
      // assuming only promos on ForYou page
      return (
        this.isForYouPage &&
        this.promoComponents().includes(this.resultComponent)
      );
    },
  },

  methods: {
    isUnauth,
    promoComponents(): string[] {
      return [learningPathResult];
    },
    returnLargeCardGroup() {
      if (this.isExplorePage && this.vssWidth >= 1800) {
        return 3;
      } else if (this.vssWidth >= 1440) {
        return this.isExplorePage ? 2 : 3;
      } else if (this.vssWidth >= 896) {
        return this.isExplorePage ? 1 : 2;
      }
      return 1;
    },
    returnSmallCardGroup() {
      if (this.isExplorePage) {
        return this.returnSmallCardGroupExplore();
      } else if (this.vssWidth >= 1536) {
        return 4;
      } else if (this.vssWidth >= 1104) {
        return 3;
      } else if (this.vssWidth >= 672) {
        return 2;
      }
      return 1;
    },
    returnSmallCardGroupExplore() {
      if (this.vssWidth >= 1776) {
        return 4;
      } else if (this.vssWidth >= 1536) {
        return 3;
      } else if (this.vssWidth >= 1052) {
        return 2;
      }
      return 1;
    },
    storeSwiperInstance(swiper: any) {
      this.swiper = swiper;
    },
    storeSwiperPaginationNum(swiper: any) {
      this.swiperPages = swiper.snapGrid.length;
    },
    setSnapIndex(swiper: any) {
      this.swiperIndex = swiper.snapIndex;
    },
    correctData(result: Result) {
      if (result.catalog_data) {
        if (result.RelevancyScore) {
          result.catalog_data.relevance = result.RelevancyScore;
        } else if (result.Confidence) {
          result.catalog_data.relevance = result.Confidence;
        }
        return result.catalog_data;
      }
      return result;
    },

    backtoStart(x: any) {
      x.slideTo(0);
    },

    routePath(): string {
      return this?.$route?.path ? this?.$route?.path : "";
    },

    onSlideChange() {
      const titles = {
        "learning-path-result": "Learning Paths",
        "cert-card": "Explore Cisco's Courses",
        "videos-card": "Recommended Videos",
        "pod-cast-card": "Top Cisco U. Podcasts",
        "event-result": "Events",
        "instructor-led-result": "Instructor-Led Training",
        "practice-exam-result": "Practice Exam",
        "challenge-result": "Challenges",
        "tutorial-result": "Tutorials",
      };

      const title = titles[this.resultComponent as keyof typeof titles];

      telemetry.carousel_change(
        "carousel-change", // ok
        title, //ok
        window.btoa(title), // ok
        window.location.href,
        this.isUnauth()
      );
    },
    slideTo(slide: number) {
      this.swiper.slideTo(slide);
    },
    getWindowSize() {
      this.vssWidth = window.innerWidth;
    },
  },
  mounted() {
    this.getWindowSize();
    window.addEventListener("resize", this.getWindowSize);
  },
  unmounted() {
    window.removeEventListener("resize", this.getWindowSize);
  },
});
</script>

<template>
  <div
    :class="[
      'relative pt-10 xxl:pt-20',
      {
        'md:min-h-[15.5rem]': results && results.length > 0,
        'pb-20': isPodcastPage,
      },
    ]"
  >
    <div
      :class="[
        'text-22 font-light text-black-dark dark:text-white-dark md:text-29',
        {
          'mx-auto md:px-4 xxl:mx-20 xxl:w-auto xxl:px-0 xlg:mx-auto xlg:max-w-[100rem]':
            !isExplorePage && !isCertificationPage,
          'xlg:ml-6 xlg:max-w-[80rem]': isExplorePage,
        },
      ]"
    >
      <h2
        data-cy="subtitle-events"
        :class="[
          'mx-4 flex items-center justify-between text-18 sm:mx-6 sm:text-22',
          {
            'md:mx-2 xxl:mx-0':
              isForYouPage || isPodcastPage || isCommunityLandingPage,
            'mx-0 xlg:mx-0': isExplorePage,
          },
        ]"
      >
        <slot />
        <router-link
          tabindex="0"
          v-show="results.length > 0 && !hideViewAll"
          :data-cy="viewAllTag"
          :to="viewAllLink"
          class="flex flex-none cursor-pointer items-center text-14 leading-[1.2] text-blue-light"
          :aria-label="viewAllText"
        >
          <span
            :class="[
              'sr-only',
              {
                'md:!not-sr-only':
                  isForYouPage || isPodcastPage || isCommunityLandingPage,
                'xl:!not-sr-only': isExplorePage,
              },
            ]"
            >{{ viewAllText }}</span
          >
          <cu-icon-chevron-right class="ml-0.5"></cu-icon-chevron-right>
        </router-link>
      </h2>

      <div
        v-if="results.length === 0 && !fetched"
        class="skeleton-loader mx-auto mb-10 mt-6"
      >
        <skeleton-loader :limit="5" />
      </div>

      <div
        v-else-if="results.length === 0 && fetched"
        class="container mx-auto mb-20 mt-6 md:px-4"
      >
        <div
          class="flex flex-col items-center justify-center rounded-md border border-dashed border-gray-med py-[3.75rem] font-light text-black-darkest"
        >
          <p class="mb-4 text-22">
            {{ noResultsText
            }}<span v-if="topicsString">
              for <strong class="font-bold">{{ topicsString }}</strong></span
            >.
          </p>

          <p class="text-16" v-if="isForYouPage">
            Explore all
            <router-link
              tabindex="0"
              :data-cy="viewAllTag"
              :to="viewAllLink"
              class="text-blue-light hover:text-blue md:relative md:bottom-0 md:inline-block"
            >
              {{ exploreAllText }}
            </router-link>
          </p>
        </div>
      </div>
    </div>

    <div
      :class="[
        'mb-4 xlg:mx-auto xlg:max-w-[100rem]',
        {
          'flex w-screen justify-center overflow-x-visible xxl:mx-20 xxl:w-auto':
            !isExplorePage && !isCertificationPage,
        },
        {
          'xxl:ml-6 xlg:max-w-[80rem]': isExplorePage,
        },
      ]"
      v-show="results?.length > 0 && fetched"
    >
      <div
        ref="carouselWrapper"
        :class="[
          'w-full justify-start pt-4',
          {
            'md:w-full':
              isForYouPage || isCommunityLandingPage || isMyCommunityPage,
          },
          { 'xl:w-full': isExplorePage && isCertificationPage },
          { 'sm:w-6/12 md:w-7/12 lg:w-full': isCertificationPage },
        ]"
      >
        <swiper
          :class="[
            'swiper overflow-hidden',
            { 'dark-variant': dark },
            { 'w-full': !isExplorePage && !isCertificationPage },
          ]"
          :center-insufficient-slides="false"
          :freemode="true"
          :keyboard="{ enabled: true }"
          :grab-cursor="true"
          :modules="modules"
          :mousewheel="false"
          :passive-listeners="true"
          :observer="true"
          :observe-slide-children="true"
          :slides-per-group="slidesPerGroup"
          :slides-per-group-auto="true"
          @slideChange="onSlideChange()"
          :slides-per-view="'auto'"
          @slidesLengthChange="backtoStart"
          @swiper="storeSwiperInstance"
          @snapIndexChange="setSnapIndex"
          @snapGridLengthChange="storeSwiperPaginationNum"
        >
          <swiper-slide
            v-for="(result, index) in selectedResults"
            :key="index"
            class="hide-scrollbar flex flex-1 shrink grow-0 overflow-x-visible"
          >
            <the-cards
              :result="correctData(result as Result)"
              :status="status"
              tabindex="0"
              v-if="result.type !== 'promo'"
            />
            <div v-else class="flex-2 flex">
              <cu-promo-card
                heading="Elevate your skills"
                description="Want to stand out in tech? Cisco Certifications make all the difference."
                btntext="Learn more"
                btnLink="/certifications"
                theme="dark"
                class="h-[22rem] w-72"
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div
      v-if="swiperPages > 1"
      :class="[
        'swiper-cu-pagination mx-6 hidden justify-end xxl:mx-20 xxl:justify-end xlg:mx-auto xlg:max-w-[100rem]',
        {
          'xxl:mr-6 xlg:ml-6 xlg:max-w-[80rem]': isExplorePage,
        },
      ]"
    >
      <cu-pagination
        :dark="isMyCommunityPage || undefined"
        :carousel="!isMyCommunityPage || undefined"
        :total="swiperPages"
        :current="swiperIndex + 1"
        @page-num="
          ({ detail }: any) => slideTo((detail.pageNum - 1) * slidesPerGroup)
        "
      ></cu-pagination>
    </div>
    <div class="hidden" v-show="results?.length == 0 && fetched">
      No search results
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto !important;
  padding-left: 1rem;
}

.swiper-slide:last-child {
  padding-right: 1rem;
}

.swiper-slide:hover {
  z-index: 1000;
}

@media screen and (min-width: 40rem) {
  .swiper-slide:first-child {
    padding-left: 1.5rem;
  }

  .swiper-slide:last-child {
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 56rem) {
  .swiper-cu-pagination {
    display: flex;
  }
}

@media screen and (min-width: 90rem) {
  .swiper-slide {
    padding-left: 0;
    padding-right: 1rem;
  }

  .swiper-slide:first-child {
    padding-left: 0;
  }

  .swiper-slide:last-child {
    padding-right: 0;
  }
}
</style>
