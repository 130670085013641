<script lang="ts">
import { debounce } from "lodash-es";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import theCard from "@/components/contentBits/theCard.vue";
import getDuration from "@/utils/getDuration";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

export default defineComponent({
  components: {
    theCard,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    status: {
      type: Object,
      default: null,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
    topicView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      inMyList: "user/inMyList",
      contentAuthorized: "content/authorizedContent",
      freeId: "content/freeId",
    }),
    showChallenge() {
      return window.env.ENABLE_CTF;
    },
    resultLink() {
      let challengeId;

      if (window.env.FRIENDLY_URL_FF && this.result?.friendly_url?.length > 0) {
        challengeId = this.result.friendly_url;
      } else {
        challengeId = this.result.id;
      }

      if (!challengeId) {
        return "";
      }
      return this.$router.resolve({
        name: "Challenge",
        params: { challengeId },
      }).href;
    },
  },
  methods: {
    isUnauth,
    getDuration,
    ...mapActions({
      fetchListByType: "user/fetchListByType",
      removeContentFromMyList: "user/removeContentFromMyList",
      addContentToMyList: "user/addContentToMyList",
    }),

    bookmarkMe(content: any) {
      this.inMyList(content)
        ? this.removeContentFromMyList({
            routeName: this.$route.name,
            result: content,
          })
        : this.addContentToMyList({
            routeName: this.$route.name,
            result: content,
          });
    },
    debouncedBookmarkMe: debounce(function (content: any) {
      // @ts-ignore
      this.bookmarkMe(content);
    }, 500),
    async sendTelemetry() {
      let telemetryResponse = telemetry.external(
        {
          action: "launch-contentType",
          url: this.resultLink,
          title: this.result.title,
          id: this.result.id,
          page: this.$route.fullPath,
          type: this.result.type,
          relevance: this.result.relevance,
          sortOrder: this.index,
        },
        this.isUnauth()
      );
      telemetryResponse
        .then(() => {
          this.$router.push(this.resultLink);
        })
        .catch(() => {
          // do nothing
        });
    },
  },
});
</script>

<template>
  <div
    :class="[
      'relative z-10',
      {
        'w-[19rem]': cardView === 'Grid' && !topicView,
        'w-full': cardView != 'Grid',
        'w-[22rem]': topicView,
      },
    ]"
    data-cy="challenge-result"
    v-if="showChallenge"
    v-show="result"
  >
    <the-card
      :is-unauth="isUnauth()"
      :card-view="cardView"
      :result="result"
      :status="status"
      :cy="'challenge-card'"
      :link="resultLink"
      @navigate="sendTelemetry"
      @bookmark="debouncedBookmarkMe(result)"
    />
  </div>
</template>
