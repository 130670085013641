<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-cart-thick.js";
import "@cisco-u/text-badge/cu-text-badge.js";
import "@cisco-u/notification/cu-notification.js";

import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import AiPromptBtn from "@/components/AiPromptBtn.vue";
import CalloutSearchItem from "@/components/CalloutSearchItem.vue";
import ProfileIcon from "@/layout/Header/dropDown.vue";
import nav from "@/layout/Header/nav.json";
import PageNav from "@/layout/Header/PageNav.vue";
import SearchBar from "@/layout/Header/searchBar.vue";
import { EventBus } from "@/store/eventBus";
import SanitizeUrl from "@/utils/sanitizeUrl";
import telemetry from "@/utils/telemetry";

const searchAll = "search-all";
const searchType = "search-type";

export default defineComponent({
  components: {
    SearchBar,
    PageNav,
    ProfileIcon,
    AiPromptBtn,
    CalloutSearchItem,
  },
  emits: ["update-query"],
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    scrolled: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    instance: {
      type: String,
      default: "",
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cartItems: 0,
      query: this.searchQuery,
      errorMounted: "",
    };
  },
  mounted() {
    this.queryParamSetup();
    // Retrieve cart quantity from local storage
    const savedCartItems = localStorage.getItem("cartItems");
    if (savedCartItems) {
      this.cartItems = parseInt(savedCartItems, 10);
    }

    // Remove any existing listeners before adding a new one
    EventBus.off("updateQty");
    EventBus.on("updateQty", (qty: number) => {
      this.cartItems = qty;
      // Save cart quantity to local storage
      localStorage.setItem("cartItems", qty.toString());
    });
  },
  computed: {
    ...mapGetters({
      isAdminRole: "user/adminRole",
      isCustomerAdminRole: "user/customerAdminRole",
    }),
    customerAdminUrl(): string {
      return SanitizeUrl(window.env.CUSTOMER_ADMIN_URL);
    },
    adminUrl(): string {
      return SanitizeUrl(window.env.CISCO_ADMIN_URL);
    },
    isSearchRoute(): boolean {
      return (
        this.$route &&
        [searchAll, searchType].includes(this.$route.name as string)
      );
    },
    showGenAi() {
      return window.env.SHOW_GEN_AI;
    },
    showProfile() {
      if (this.$route?.meta?.scrollShowProfile) {
        return true;
      }
      return this.scrolled;
    },
    showNav() {
      return this.$route?.meta?.hideMarquee;
    },
    noRounding(): boolean {
      return (
        (this.$route?.meta?.pageHeader as boolean) &&
        (this.$route?.meta?.hideMarquee as boolean)
      );
    },
    isExploreSubPages() {
      return ["/explore/", "/podcast/"].includes(this.$route.path);
    },
    navigationList() {
      let navigation = nav;
      const { LEARN_PAGE_FLAG, ENABLE_TOPICS_DISCOVERY } = window.env;

      if (!LEARN_PAGE_FLAG || !ENABLE_TOPICS_DISCOVERY) {
        navigation = navigation.filter((navItem) => navItem.title !== "Learn");
      }

      if (ENABLE_TOPICS_DISCOVERY) {
        navigation = navigation.filter(
          (navItem) => navItem.title !== "Explore"
        );
      }

      // nav, but remove the last item
      return navigation.slice(0, -1);
    },
    updatedHeader() {
      return window.env.UPDATED_HEADER_FLAG;
    },
  },
  watch: {
    $route() {
      this.queryParamSetup();
    },
    searchQuery(newQuery) {
      this.query = newQuery;
    },
  },
  methods: {
    goToCart() {
      if (window.location.pathname == "/purchase") {
        EventBus.emit("showCart");
      } else {
        this.$router.push({ name: "purchase" });
      }
    },
    updateQuery(newValue: any) {
      this.$emit("update-query", newValue);
    },
    queryParamSetup() {
      if (this.isSearchRoute && this.$route.query && this.$route.query.query) {
        this.query = this.$route.query.query as string;
        if (this.errorMounted !== "") {
          telemetry.custom404("learner-navPath", this.errorMounted, "Search");
        }
      }
    },
    startSearching(e: any) {
      this.query = e?.detail?.search;
      if (this.query && this.query.length >= 2) {
        this.redirectToSearch();
      }
    },
    redirectToSearch() {
      this.$router.push({ name: searchAll, query: { query: this.query } });
    },
  },
});
</script>

<template>
  <div
    :data-cy="`page-header-${instance}`"
    :class="[
      'z-[1000]',
      {
        'rounded bg-black-dark': dark,
        'bg-white-lightest pt-0 dark:bg-black-lightest': !dark,
        'left-0 right-0 top-0 fixed': scrolled || noRounding,
        'rounded-t-40': !scrolled && !noRounding,
      },
    ]"
    v-if="updatedHeader"
  >
    <callout-search-item v-if="dark || showLogo" class="lg:text-22" />

    <cu-top-header
      @cu-header-search="startSearching"
      :searchtext="query"
      logolink="/"
      :dark="dark"
      v-if="showLogo || showNav"
      data-cy="page-header"
    >
      <router-link
        v-for="navitem in navigationList"
        :key="navitem.title"
        :data-cy="!dark ? navitem.navCy : `${navitem.navCy}-dark`"
        active-class="active"
        :to="{ name: navitem.route }"
        slot="nav-item"
        :break="navitem.break || null"
        >{{ navitem.title }}</router-link
      >

      <ai-prompt-btn
        v-if="showGenAi && (showLogo || showNav)"
        slot="action-item"
        class="p-[0.5625rem]"
      />
      <profile-icon
        v-if="showLogo || showNav"
        :scrolled="showProfile"
        :show-pic="showProfile"
        :dark="dark"
        profile-dropdown="toggle-dropdown"
        slot="action-item"
      />
      <cu-notification
        slot="action-item"
        data-cy="user-cart"
        size="m"
        type="cart"
        @click="goToCart"
        :items="cartItems"
        :dark="dark"
        >Go to Cart</cu-notification
      >

      <cu-top-mobile-section slot="mobile-nav-item">
        <router-link
          v-for="navitem in navigationList"
          :key="navitem.title"
          :to="{ name: navitem.route }"
          v-slot="{ href }"
          custom
        >
          <cu-top-mobile-item
            :href="href"
            :active="navitem.route === $route.name"
            :data-cy="
              !dark ? `${navitem.navCy}-mobile` : `${navitem.navCy}-mobile-dark`
            "
            >{{ navitem.title }}</cu-top-mobile-item
          >
        </router-link>
      </cu-top-mobile-section>

      <cu-top-mobile-section slot="mobile-nav-item">
        <router-link :to="{ name: 'dashboard' }" v-slot="{ href }" custom>
          <cu-top-mobile-item data-cy="my-dashboard" :href="href"
            >My Dashboard</cu-top-mobile-item
          >
        </router-link>
        <cu-top-mobile-item
          v-if="isCustomerAdminRole"
          data-cy="customer-admin-mobile"
          :href="customerAdminUrl"
          rel="noopener"
          >Customer Admin</cu-top-mobile-item
        >
        <cu-top-mobile-item
          v-if="isAdminRole"
          data-cy="admin-mobile"
          :href="adminUrl"
          target="_blank"
          rel="noopener"
          >Cisco Admin</cu-top-mobile-item
        >
      </cu-top-mobile-section>

      <cu-top-mobile-item slot="mobile-footer-item"
        >Help &amp; Support</cu-top-mobile-item
      >
      <cu-top-mobile-item slot="mobile-footer-item"
        >Sign Out</cu-top-mobile-item
      >
    </cu-top-header>
  </div>

  <div
    :data-cy="`page-header-${instance}`"
    :class="[
      'z-[1000]',
      {
        'rounded bg-black-dark': dark,
        'bg-white-lightest pt-0 dark:bg-black-lightest': !dark,
        'left-0 right-0 top-0 lg:fixed': scrolled || noRounding,
        'rounded-t-40': !scrolled && !noRounding,
      },
    ]"
    v-else
  >
    <callout-search-item v-if="dark || showLogo" class="lg:text-22" />

    <div
      :class="[
        'container hidden flex-row items-center justify-between pb-6 font-light lg:flex lg:px-4',
        {
          'pt-4 text-22 text-white-lightest lg:pt-10': dark,
          'text-16 lg:pt-8 lg:text-22': !dark,
          'mx-auto': !isExploreSubPages,
          'mr-4 lg:mx-4 lg:ml-[3.188rem]': isExploreSubPages,
        },
      ]"
    >
      <page-nav
        :dark="dark"
        :show-logo="showLogo || noRounding"
        v-if="showLogo || showNav"
      />
      <div class="hidden items-center justify-between lg:flex lg:flex-row">
        <search-bar
          :dark="dark"
          :show-logo="showLogo || noRounding"
          :instance="instance"
          nav-search="search-box"
          search-btn="search"
          :searchQuery="searchQuery"
          @update-query="updateQuery"
          v-if="showLogo || showNav"
        />
        <ai-prompt-btn
          v-if="showGenAi && (showLogo || showNav)"
          class="ml-4 xl:ml-10"
        />
        <profile-icon
          class="ml-4 block xl:ml-10"
          :scrolled="showProfile"
          :show-pic="showProfile"
          :dark="dark"
          profile-dropdown="toggle-dropdown"
          v-if="showLogo || showNav"
        />
        <div
          v-if="showLogo || showNav"
          class="ml-4 flex cursor-pointer flex-col items-center pb-2.5 xl:ml-10"
        >
          <cu-text-badge
            v-if="cartItems > 0"
            class="relative left-[0.1rem] top-[0.79rem] z-10 cursor-pointer"
            :text="cartItems"
            bgColor="red-secondary"
            color="white"
            data-cy="user-cart-item-badge"
            medium="true"
            @click="goToCart"
          />
          <cu-icon-cart-thick
            @click="goToCart"
            size="xl"
            data-cy="user-cart-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>
