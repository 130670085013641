<template>
  <a
    href="search/tutorial?query=AI chatgpt"
    class="block bg-blue-dark px-6 py-4 text-center text-16 leading-[1.2] -tracking-[0.0275rem] text-white-lightest hover:underline"
    active-class="underline"
    ><strong class="font-thicker"
      >Free AI training and tutorials from Cisco U.</strong
    >
    From entry to expert, gain real-world skills to succeed in AI.</a
  >
</template>
