<script lang="ts">
import { debounce } from "lodash-es";
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import theCard from "@/components/contentBits/theCard.vue";
import { removeProgress } from "@/services/middlewareService/learnerProgress";
import { add } from "@/services/profileService/myList";
import { updateUserActions } from "@/services/profileService/userActions";
import { urlContentTypes } from "@/utils/contentRemovable";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

export default defineComponent({
  components: {
    theCard,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    status: {
      type: Object,
      default: null,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    topicView: {
      type: Boolean,
      default: false,
    },
    isAdminContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hideRecommendation: false,
    };
  },
  computed: {
    subLink() {
      return this.$router.resolve({
        name: "subscription",
      }).href;
    },
    ...mapGetters({
      inMyList: "user/inMyList",
      achievements: "user/achievements",
      freeId: "content/freeId",
    }),
    freeContent(): boolean {
      return this.result?.content_bundle?.includes(this.freeId);
    },
    showPremium(): boolean {
      return !this.status?.userEntitlements?.includes(this.result?.guid);
    },
    resultLink() {
      let tutorialId;
      if (window.env.FRIENDLY_URL_FF && this.result?.friendly_url?.length > 0) {
        tutorialId = this.result.friendly_url;
      } else {
        tutorialId = this.result.id;
      }

      if (!tutorialId) {
        return "";
      }
      return this.$router.resolve({
        name: "Tutorial",
        params: { tutorialId },
      }).href;
    },
  },
  methods: {
    isUnauth,
    whereTo() {
      this.sendTelemetry();
    },
    ...mapMutations({
      removeFromMyProgress: "user/removeFromMyProgress",
    }),
    ...mapActions({
      fetchListByType: "user/fetchListByType",
      removeContentFromMyList: "user/removeContentFromMyList",
      removeProgress: "user/removeProgress",
      fetchProgress: "user/fetchProgress",
    }),
    addContentToMyList() {
      const param_type = this.$route.name != "forYou" ? this.result.type : null;
      add({
        type: param_type,
        content_id: this.result.id ? parseInt(this.result.id) : null,
        content_guid: this.result.guid != "" ? this.result.guid : null,
        deleted_at: null,
      })
        ?.then(() => {
          this.fetchListByType(param_type);
        })
        .catch(() => {
          // do nothing
        });

      telemetry.add_to_list(
        "add-contentType-to-list",
        this.$route.fullPath,
        "tutorial",
        this.result.title,
        this.result.id
      );
    },

    bookmarkMe(content: any) {
      this.inMyList(content)
        ? this.removeContentFromMyList({
            routeName: this.$route.name,
            result: content,
          })
        : this.addContentToMyList();
    },
    debouncedBookmarkMe: debounce(function (content: any) {
      // @ts-ignore
      this.bookmarkMe(content);
    }, 500),
    async sendTelemetry() {
      let telemetryResponse = telemetry.external(
        {
          action: "launch-contentType",
          url: window.location.origin + this.resultLink,
          title: this.result.title,
          id: this.result.id,
          page: this.$route.fullPath,
          type: this.result.type,
          relevance: this.result.relevance,
          sortOrder: this.result?.assigned_content
            ? this.result.assigned_content?.index
            : this.index,
          assignedContent: this.result?.assigned_content
            ? this.result.assigned_content?.isAssigned
            : undefined,
        },
        this.isUnauth()
      );
      telemetryResponse
        .then(() => {
          if (this.resultLink.startsWith("https://")) {
            window.open(this.resultLink, "_self", "noopener");
          } else {
            this.$router.push(this.resultLink);
          }
        })
        .catch(() => {
          // do nothing
        });
    },
    hideCTA(guid: any) {
      this.hideRecommendation = true;
      updateUserActions({
        guid: guid,
        element: "NoThanks",
        action: "Close",
      });
    },

    async sendRmTelemetry(content: any) {
      const contentId = content.content_id || content.id || "";
      return await telemetry.remove_from_myLearning(
        window.location.origin + this.$route.fullPath,
        `/${urlContentTypes(content.type)}/${contentId}`,
        content.title || content.name || "",
        content.type,
        contentId,
        this.isUnauth()
      );
    },
    async removeFromMyProgressList(content: any) {
      removeProgress(content.guid, content.type)
        .then(() => {
          this.sendRmTelemetry(content);
          this.removeFromMyProgress(content);
          this.fetchProgress();
        })
        .catch(() => {
          //do nothing
        });
    },
  },
});
</script>

<template>
  <div
    v-if="result && !hideRecommendation"
    :class="[
      'relative z-10',
      {
        'w-[19rem]': cardView === 'Grid' && !topicView,
        'w-full': cardView != 'Grid',
        'w-[22rem]': topicView,
      },
    ]"
  >
    <div data-cy="tutorial-card" class="relative">
      <the-card
        :is-unauth="isUnauth()"
        :card-view="cardView"
        :result="result"
        :status="status"
        :cy="'tutorial-card-link'"
        :is-removable="isRemovable"
        :link="resultLink"
        @navigate="whereTo"
        @bookmark="debouncedBookmarkMe(result)"
        @remove-progress="removeFromMyProgressList(result)"
        :is-admin-content="isAdminContent"
      />
    </div>
  </div>
</template>
