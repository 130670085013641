import { z } from "zod";

import http from "@/services/http";
import ExamWithCertification from "@/services/catalogService/ExamWithCertification";

const CourseIdPath = z.object({
  id: z.number(),
  type: z.string(),
  guid: z.string(),
  title: z.string(),
  paths: z.array(
    z.object({
      id: z.number(),
      guid: z.string(),
      type: z.string(),
      name: z.string(),
      acronym: z.string().nullish(),
      display_version: z.string().nullish(),
    })
  ),
});

export async function fetch(Id: string) {
  const { data } = await http()
    .get(`${window.env.CATALOG_API_LOCATION}/courses/${Id}`)
    .catch((e) => {
      return e.response.data;
    });
  return data;
}

export async function carousel(type: string) {
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/courses?type=${type}&length=18`
  );

  return data.data;
}

export async function retrieveCatalogItems(
  guids: Array<string>,
  type?: string
) {
  const { data } = await http().post(
    `${window.env.CATALOG_API_LOCATION}/retrieveCatalogItems`,
    {
      guids: guids,
      type: type ?? null,
    }
  );

  return data.Data;
}

export async function retrieveCatalogById(ids: Array<number>, type?: string) {
  const { data } = await http().post(
    `${window.env.CATALOG_API_LOCATION}/retrieveCatalogItems`,
    {
      ids: ids,
      type: type ?? null,
    }
  );

  return data.Data;
}

export async function retrieveAllCatalogItems(
  guids: Array<string>,
  ids: Array<number>,
  type?: string
) {
  const { data } = await http().post(
    `${window.env.CATALOG_API_LOCATION}/retrieveCatalogItems`,
    {
      guids: guids.length ? guids : null,
      ids: ids.length ? ids : null,
      type: type ?? null,
    }
  );

  return data.Data;
}

export async function getCoursePaths(id: number) {
  if (!window.env.PROFILE_API_LOCATION || id < 0) {
    return;
  }
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/courses/${id}/paths`
  );

  return CourseIdPath.parse(data);
}

export async function getExam(
  examIds: Array<string>,
  includeCertifications?: boolean,
  includeTopics?: boolean
) {
  const { data } = await http().post(
    `${window.env.CERTIFICATION_API_LOCATION}/exams/search`,
    {
      exam_number: examIds,
      include_certifications: includeCertifications,
      include_topics: includeTopics,
      include_prescribed_prep_material: true,
    }
  );
  return ExamWithCertification.parse(data.data);
}
