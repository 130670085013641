<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

import AnchorTargetBlank from "@/components/AnchorTargetBlank.vue";
import AnchorTargetSelf from "@/components/AnchorTargetSelf.vue";

export default defineComponent({
  components: {
    AnchorTargetBlank,
    AnchorTargetSelf,
  },
  props: {
    showDropDown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      surveyId: 0,
      adminUrl: window.env.CISCO_ADMIN_URL,
      customerAdminUrl: window.env.CUSTOMER_ADMIN_URL,
      productSSOTUrl: window.env.PRODUCT_SSOT_URL,
    };
  },
  computed: {
    ...mapGetters({
      isAdminRole: "user/adminRole",
      isCustomerAdminRole: "user/customerAdminRole",
      isContentAdminRole: "user/contentAdminRole",
    }),
    updatedHeader() {
      return window.env.UPDATED_HEADER_FLAG;
    },
  },

  methods: {
    ...mapMutations({
      hideDropDown: "user/hideDropDown",
    }),

    clearStorage() {
      localStorage.clear();
      window.location.href = "/sso-logout";
    },
  },
});
</script>

<template>
  <div
    data-cy="drop-down-wrapper"
    id="dropdown"
    :class="{
      'absolute top-10 right-[3.25rem] z-[1200] mt-24 w-[15.25rem] rounded-xl bg-white-lightest text-left text-16 text-black-dark shadow-xl dark:bg-black-dark dark:text-white-dark md:right-[3.75rem] xlg:right-[4.25rem]':
        updatedHeader,
      'absolute top-[8.9375rem] z-[1200] mt-24 w-full rounded-xl bg-white-lightest text-left text-16 text-black-dark shadow-xl dark:bg-black-dark dark:text-white-dark lg:right-[5.625rem] lg:top-[5.625rem] lg:w-[15.25rem]':
        !updatedHeader,
    }"
    v-click-outside="hideDropDown"
    @click="hideDropDown"
    @keyup.enter="hideDropDown"
    @keyup.space="hideDropDown"
  >
    <div class="flex cursor-pointer flex-col text-left focus:outline-black">
      <router-link
        tabindex="0"
        data-cy="my-dashboard"
        :to="{ name: 'dashboard' }"
        class="border-b px-6 py-4 text-22 font-slight hover:rounded-t-lg hover:bg-blue-light hover:text-white-lightest"
        >My Dashboard</router-link
      >
      <router-link
        tabindex="0"
        data-cy="my-list"
        :to="{ name: 'my-list' }"
        class="px-6 py-2 font-slight hover:bg-blue-light hover:text-white-lightest"
        >My Learning</router-link
      >
      <router-link
        tabindex="0"
        data-cy=""
        :to="{ name: 'community' }"
        class="px-6 py-2 font-slight hover:bg-blue-light hover:text-white-lightest"
        >My Communities</router-link
      >
      <router-link
        tabindex="0"
        data-cy="my-account"
        :to="{ name: 'my-account' }"
        class="px-6 py-2 font-slight hover:bg-blue-light hover:text-white-lightest"
        >My Account</router-link
      >
      <anchor-target-self
        v-if="isCustomerAdminRole"
        data-cy="customer-admin"
        :url="customerAdminUrl"
        class="border-b border-t px-6 pb-2 pt-2 text-22 font-slight hover:bg-blue-light hover:text-white-lightest"
      >
        Customer Admin
      </anchor-target-self>

      <anchor-target-self
        v-if="isCustomerAdminRole"
        tabindex="0"
        data-cy="customer-subscriptions"
        :url="customerAdminUrl + '/subscriptions'"
        rel="noopener"
        class="px-6 py-2 font-slight hover:bg-blue-light hover:text-white-lightest"
      >
        Orders
      </anchor-target-self>
      <anchor-target-self
        v-if="isCustomerAdminRole"
        data-cy="customer-learners"
        :url="customerAdminUrl + '/learners'"
        class="px-6 py-2 font-slight hover:bg-blue-light hover:text-white-lightest"
      >
        Learners
      </anchor-target-self>
      <anchor-target-blank
        v-if="isContentAdminRole"
        data-cy="content-admin"
        :url="productSSOTUrl + '/catalogmanagement'"
        class="flex items-center border-t px-6 py-2 text-22 font-slight hover:bg-blue-light hover:text-white-lightest"
      >
        Catalog Management
      </anchor-target-blank>
      <anchor-target-blank
        data-cy="help-support"
        url="https://servicedesk.certsite.net/jira/servicedesk/customer/portal/3"
        class="flex items-center border-t px-6 py-2 text-22 font-slight hover:bg-blue-light hover:text-white-lightest"
      >
        Help & Support
      </anchor-target-blank>
      <anchor-target-blank
        v-if="isAdminRole"
        data-cy="admin"
        :url="adminUrl"
        class="border-t px-6 pb-2 pt-2 text-22 font-slight hover:bg-blue-light hover:text-white-lightest"
      >
        Admin
      </anchor-target-blank>
      <div
        class="group border-t px-6 pb-2 pt-2 text-22 hover:rounded-b-lg hover:bg-blue-light hover:text-white-lightest focus:outline-black"
      >
        <a
          class="flex items-center font-slight"
          data-cy="logout"
          @click.prevent="clearStorage"
          @keyup.enter="clearStorage"
          @keyup.space="clearStorage"
          href="/sso-logout"
        >
          Sign Out
        </a>
      </div>
    </div>
  </div>
</template>
