import { z } from "zod";

import http from "@/services/http";
import unauthenticatedHttp from "@/services/unauthenticatedHttp";
import isUnauth from "@/utils/isUnauth";

const GlobalProductSchema = z.object({
  content_bundle_id: z.number().nullable().optional(),
  created_at: z.string().nullable().optional(),
  eol_date: z.string().nullable().optional(),
  eos_date: z.string().nullable().optional(),
  ga_date: z.string().nullable().optional(),
  global_product_id: z.number().nullable().optional(),
  global_product_name: z.string().nullable().optional(),
  id: z.number().nullable().optional(),
  is_single: z.number().nullable().optional(),
  updated_at: z.string().nullable().optional(),
});

const CatalogDataSchema = z
  .object({
    active: z.number().nullable().optional(),
    acronym: z.string().nullable().optional(),
    adjacent_technology: z.string().nullable().optional(),
    associated_descriptions: z.array(z.string()).nullable().optional(),
    associated_guids: z.array(z.string()).nullable().optional(),
    associated_titles: z.array(z.string()).nullable().optional(),
    author: z.string().nullable().optional(),
    content_bundle: z.array(z.number()).nullable().optional(),
    content_count: z.number().nullable().optional(),
    content_provider: z.string().nullable().optional(),
    content_type_id: z.number().nullable().optional(),
    copyright_holder: z.string().nullable().optional(),
    copyright_year: z.number().nullable().optional(),
    created_at: z.string().nullable().optional(),
    data_classification: z.string().nullable().optional(),
    deleted_at: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    difficulty_type: z.string().nullable().optional(),
    difficulty_type_id: z.number().nullable().optional(),
    display_version: z.string().nullable().optional(),
    duration_sec: z.number().nullable().optional(),
    friendly_url: z.string().nullable().optional(),
    global_products: z.array(GlobalProductSchema).nullable().optional(),
    guid: z.string().nullable().optional(),
    id: z.number().nullable().optional(),
    is_partial: z.number().nullable().optional(),
    is_private: z.number().nullable().optional(),
    language: z.string().nullable().optional(),
    language_code: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    number_of_assessments: z.number().nullable().optional(),
    number_of_credits: z.number().nullable().optional(),
    number_of_labs: z.number().nullable().optional(),
    presenter: z.string().nullable().optional(),
    product_alias: z.string().nullable().optional(),
    product_version: z.string().nullable().optional(),
    publisher_id: z.number().nullable().optional(),
    publisher_name: z.string().nullable().optional(),
    record_gui: z.string().nullable().optional(),
    skills_associated: z.array(z.string()).nullable().optional(),
    solutions: z.array(z.string()).nullable().optional(),
    sort_order: z.number().nullable().optional(),
    summary: z.string().nullable().optional(),
    target_availability_date: z.string().nullable().optional(),
    technology: z.string().nullable().optional(),
    title: z.string().nullable().optional(),
    topics: z.array(z.string()).nullable().optional(),
    type: z.string().nullable().optional(),
    updated_at: z.string().nullable().optional(),
    url: z.string().nullable().optional(),
  })
  .passthrough()
  .nullable()
  .optional();

const ListItemSchema = z.object({
  object_type: z.string(),
  object_id: z.string(),
  object_guid: z.string(),
  score: z.number(),
  is_new: z.boolean(),
  is_free: z.boolean(),
  catalog_data: CatalogDataSchema,
});

const ContentSchema = z.object({
  is_new_count: z.number(),
  is_free_count: z.number(),
  list: z.array(ListItemSchema),
});

const TopicContentSchema = z.object({
  topic: z.string(),
  popular: ContentSchema,
  trending: ContentSchema,
});

const OrderedTopics = z.object({
  topic: z.string(),
  score: z.number(),
});

const OrderedTopicsSchema = z.object({
  popular: z.array(OrderedTopics),
  trending: z.array(OrderedTopics),
});

const MainSchema = z.object({
  schema_version: z.number(),
  ordered_topics: OrderedTopicsSchema.nullable(),
  topic_content_lists: z.array(TopicContentSchema).nullable(),
});

export async function retrieveTopicContent(topic?: string) {
  let responseData;
  let topicUrl = "";
  if (topic) {
    topicUrl = `?topic=${topic}`;
  }
  if (isUnauth()) {
    const { data } = await unauthenticatedHttp().get(
      `${window.env.UNAUTH_API}/learn/popular-trending` + topicUrl
    );
    responseData = data;
  } else {
    const { data } = await http().get(
      `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/topics/popular-trending` +
        topicUrl
    );
    responseData = data;
  }
  return MainSchema.parse(responseData);
}
