<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    limit: {
      type: Number,
      default: 12,
    },
    grid: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <div
    :class="['text-white lg:flex', { 'overflow-clip': !grid }]"
    data-cy="skele"
  >
    <div
      :class="[
        'ml-4 flex animate-pulse lg:ml-0',
        { 'w-[100rem] flex-row': !grid, 'flex-wrap': grid },
      ]"
    >
      <div
        :class="[
          'mr-4 inline-block h-[22rem] w-[19rem] rounded-xl bg-white',
          { 'mb-4': grid },
        ]"
        v-for="i in limit"
        :key="i"
      />
    </div>
  </div>
</template>
