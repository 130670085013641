import telemetry from "@/utils/telemetry";

export function sendButtonCtaTelemetry(
  path: string,
  title: string,
  unauth: boolean,
  scenario?: string,
  pageToGo?: string,
  input?: string,
  subNav?: string
) {
  if (scenario || pageToGo || input || subNav)
    return telemetry.click_on_cta_ceCredit(
      {
        url: path,
        title: title,
        unauth: unauth,
        objType: "button",
        scenario: scenario,
        pageToGo: pageToGo,
      },
      input,
      subNav
    );
  return telemetry.click_on_cta(path, title, unauth, "button");
}

export function sendLinkCtaTelemetry(
  path: string,
  title: string,
  unauth: boolean,
  scenario?: string,
  pageToGo?: string
) {
  if (scenario || pageToGo)
    return telemetry.click_on_cta_ceCredit({
      url: path,
      title: title,
      unauth: unauth,
      objType: "link",
      scenario: scenario,
      pageToGo: pageToGo,
    });
  return telemetry.click_on_cta(path, title, unauth, "link");
}
