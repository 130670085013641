export function validUrl(url: string, validPrefixes: string[]): boolean {
  const prefixesPattern = validPrefixes.join("|");

  const regex = new RegExp(`\/(${prefixesPattern})\/\\d+`);
  return regex.test(url);
}

export function isXylemeSyndicateCourse(result: any): boolean {
  //should return true for URLs that start with https:// and include cisco.bravais.com
  // or if authoring_platform is 'Create' or content_provider is 'Syndicate'

  const urlPattern =
    /^https:\/\/(?:.*[-.])?cisco(?:\.beta)?\.bravais\.com(?:\/.*)?$/;
  const isMatchingUrl = urlPattern.test(result.url);
  const isAuthoringPlatformCreate = result.authoring_platform === "Create";
  const isContentProviderSyndicate = result.content_provider === "Syndicate";

  return (
    isMatchingUrl || isAuthoringPlatformCreate || isContentProviderSyndicate
  );
}
