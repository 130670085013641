<script lang="ts">
import "@cisco-u/reicon-lock/cu-reicon-lock.js";

import dayjs from "dayjs";
import { debounce } from "lodash-es";
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import AnchorTargetBlankCallback from "@/components/AnchorTargetBlankCallback.vue";
import theCard from "@/components/contentBits/theCard.vue";
import { addUserHistory } from "@/services/profileService/addUserHistory";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

const videoTrack: string = "video-track";

export default defineComponent({
  components: {
    AnchorTargetBlankCallback,
    theCard,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    video_track: {
      type: String,
      default: videoTrack,
    },
    status: {
      type: Object,
      default: null,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
    topicView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      click: false,
    };
  },
  computed: {
    subLink() {
      return this.$router.resolve({
        name: "subscription",
      }).href;
    },
    ...mapGetters({
      inMyList: "user/inMyList",
      contentAuthorized: "content/authorizedContent",
      freeId: "content/freeId",
    }),
    freeContent(): boolean {
      return this.result?.content_bundle?.includes(this.freeId);
    },
    isVideoSeries() {
      if (this.result.type == undefined) {
        return this.result.hasOwnProperty("track_data");
      }
      return ["videoSeries", videoTrack, "track"].includes(this.result.type);
    },
    videoType() {
      if (this.isVideoSeries) {
        return "track";
      }
      return "video";
    },
    TrackId() {
      if (this.result.hasOwnProperty("tracks")) {
        return this.result.tracks ? this.result.tracks[0].id : null;
      }
      if (this.isVideoSeries || !this.result.hasOwnProperty("track_ids")) {
        return this.result.id;
      }
      return this.result.track_ids ? this.result.track_ids[0] : null;
    },
    VideoId(): string {
      if (!this.result.type) {
        return this.result.id;
      }
      if (this.result.type == "video" && this.result.id) {
        return this.result.id;
      }
      if (this.result.type != videoTrack && this.result.type != "track") {
        return this.result.doc_id;
      }

      return "";
    },
    showPremium(): boolean {
      return !this.status?.userEntitlements?.includes(this.result.guid);
    },
    resultLink() {
      let videoSerieId;
      if (window.env.FRIENDLY_URL_FF && this.result?.friendly_url?.length > 0) {
        videoSerieId = this.result.friendly_url;
      } else {
        videoSerieId = this.TrackId;
      }

      if (!videoSerieId) {
        return "";
      }

      return this.$router.resolve({
        name: "VideoSeries",
        params: { videoSerieId, videoId: this.VideoId },
        query: { return_url: encodeURIComponent(window.location.href) },
      }).href;
    },
  },
  methods: {
    isUnauth,
    hideClicked() {
      if (this.click && this.showPremium) {
        this.click = false;
      }
    },
    ...mapMutations({
      removeFromMyListResults: "user/removeFromMyListResults",
      removeFromMyLearningResults: "user/removeFromMyLearningResults",
    }),
    ...mapActions({
      removeContentFromMyList: "user/removeContentFromMyList",
      addContentToMyList: "user/addContentToMyList",
    }),
    bookmarkMe(content: any) {
      this.inMyList({ ...content, type: this.videoType })
        ? this.removeContentFromMyList({
            routeName: this.$route.name,
            result: {
              ...content,
              type: this.videoType,
            },
          })
        : this.addContentToMyList({
            routeName: this.$route.name,
            result: {
              ...content,
              type: this.videoType,
            },
          });
    },
    debouncedBookmarkMe: debounce(function (content: any) {
      // @ts-ignore
      this.bookmarkMe(content);
    }, 500),

    whereTo() {
      if (this.showPremium) {
        this.click = true;
      } else {
        this.click = false;
        this.sendTelemetry();
      }
    },

    async sendTelemetry() {
      const telemetryResponse = telemetry.external(
        {
          action: "launch-contentType",
          url: this.click ? this.subLink : this.result.id,
          title: this.click ? "Subscribe to Cisco U." : this.result.title,
          id: this.result.id,
          page: this.$route.fullPath,
          type: this.click ? "subscription offer" : this.result.type,
          relevance: this.result.relevance,
          sortOrder: this.index,
        },
        this.isUnauth()
      );
      if (!this.isUnauth()) {
        await addUserHistory({
          content_id: this.result.id,
          type: this.videoType,
          accessed: dayjs().toISOString(),
        });
      }
      telemetryResponse
        .then(() => {
          if (this.showPremium && this.subLink) {
            window.open(this.subLink, "_blank", "noopener");
          } else {
            this.$router.push(this.resultLink);
          }
        })
        .catch(() => {
          // do nothing
        });
    },
  },
});
</script>

<template>
  <div
    :class="[
      'relative z-10',
      {
        'w-[19rem]': cardView === 'Grid' && !topicView,
        'w-full': cardView != 'Grid',
        'w-[22rem]': topicView,
      },
    ]"
    data-cy="videos-card"
  >
    <the-card
      :is-unauth="isUnauth()"
      :card-view="cardView"
      :result="result"
      :status="status"
      :link="resultLink"
      :cy="'video-card-link'"
      @navigate="whereTo"
      @bookmark="debouncedBookmarkMe(result)"
    />
    <div
      :class="[
        'mt-0',
        {
          hidden: !showPremium || !click,
          'mask_it absolute left-0 top-0 mt-0 h-full w-full rounded-[0.625rem] bg-[#000000]/80':
            showPremium && click,
        },
      ]"
      v-if="click"
    >
      <cu-reicon-lock
        type="filled-keyhole"
        dark="true"
        size="xl"
        class="mt-4 flex justify-center"
      />
      <div
        class="mx-12 text-center text-13 text-white-lightest"
        v-click-outside="{
          handler: hideClicked,
          events: ['dblclick', 'click'],
          capture: true,
        }"
      >
        <div v-if="isUnauth() && contentAuthorized(result.guid)">
          <a href="/login" class="text-white-lightest underline">Sign up</a>
        </div>
        <AnchorTargetBlankCallback
          v-else
          :url="subLink"
          class="underline"
          @clicked="sendTelemetry"
        >
          <div>Upgrade your subscription</div>
        </AnchorTargetBlankCallback>
        <div v-if="isUnauth() && contentAuthorized(result.guid)">
          to explore this free video.
        </div>
        <div v-else>to unlock this video.</div>
      </div>
    </div>
  </div>
</template>
