import { z } from "zod";

const ExamWithCertification = z.array(
  z.object({
    id: z.number(),
    created_at: z.string(),
    updated_at: z.string(),
    deleted_at: z.string().nullish(),
    created_by: z.string(),
    updated_by: z.string(),
    guid: z.string(),
    certification_id: z.number(),
    exam_number: z.string().nullish(),
    acronym: z.string(),
    version: z.string(),
    title: z.string(),
    release_date: z.string().nullish(),
    eol_date: z.string().nullish(),
    duration_sec: z.number(),
    summary: z.string(),
    description: z.string().nullish(),
    type: z.string(),
    topics: z.array(
      z.object({
        id: z.number(),
        created_at: z.string(),
        updated_at: z.string(),
        deleted_at: z.string().nullish(),
        created_by: z.string(),
        updated_by: z.string(),
        topic_name: z.string(),
        guid: z.string(),
        exam_id: z.number(),
        weight: z.number(),
      })
    ),
    prescribed_prep_material: z
      .array(
        z.object({
          id: z.number(),
          created_at: z.string(),
          updated_at: z.string(),
          deleted_at: z.string().nullish(),
          created_by: z.string().nullish(),
          updated_by: z.string(),
          guid: z.string(),
          exam_id: z.number(),
          title: z.string(),
          type: z.string(),
          SortOrder: z.number(),
        })
      )
      .nullish(),
    certification: z.object({
      id: z.number(),
      created_at: z.string(),
      updated_at: z.string(),
      deleted_at: z.string().nullish(),
      created_by: z.string(),
      updated_by: z.string(),
      name: z.string(),
      acronym: z.string(),
      summary: z.string(),
      description: z.string(),
      active: z.number(),
      sort_order: z.number(),
      technology: z.array(z.string()),
      certification_level: z.string(),
      ce_credits: z.number(),
      guid: z.string(),
      job_roles: z.array(z.string()),
      skills_associated: z
        .array(z.string())
        .nullish()
        .or(z.string().default("").nullish()),
      type: z.string(),
      publisher_name: z.string(),
      language: z.string(),
      community_id: z.string(),
      community_name: z.string(),
      tag_line: z.string(),
      justification: z.string(),
      exam: z.object({}).nullish(),
    }),
  })
);

export default ExamWithCertification;
