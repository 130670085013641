<script lang="ts">
import { defineComponent } from "vue";

import SanitizeUrl from "@/utils/sanitizeUrl";

export default defineComponent({
  emits: ["clicked"],
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    cleanUrl() {
      return SanitizeUrl(this.url);
    },
  },
});
</script>

<template>
  <a
    :href="cleanUrl"
    tabindex="0"
    target="_blank"
    @click.prevent="$emit('clicked')"
    @keyup.enter.prevent="$emit('clicked')"
    @keyup.space.prevent="$emit('clicked')"
  >
    <slot />
  </a>
</template>
