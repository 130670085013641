<script lang="ts">
import "@cisco-u/base-card/cu-base-card.js";
import "@cisco-u/buttons/cu-buttons.js";
import "@cisco-u/promo-card/cu-promo-card.js";
import "@cisco-u/icons/icons/cu-icon-chevron-right.js";
import "@cisco-u/icons/icons/cu-icon-line-right.js";
import "@cisco-u/bookmark/cu-bookmark.js";
import "@cisco-u/pagination/cu-pagination.js";

import { Mousewheel, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

import theCards from "@/components/contentBits/theCards.vue";
import { Result } from "@/components/Result";
import { EventBus } from "@/store/eventBus";
import { is_progressRemovable } from "@/utils/contentRemovable";
import { sendButtonCtaTelemetry } from "@/utils/ctaTelemetry";
import SkeletonLoader from "@/views/Skeleton/Search/SkeletonLoader.vue";

export default defineComponent({
  emits: ["open"],
  props: {
    myList: {
      type: Array || null,
      default: null,
    },
    boxShowable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    theCards,
    SkeletonLoader,
  },
  data() {
    return {
      surveyCompleted: false,
      modules: [Pagination, Mousewheel],
      vssWidth: 0,
      swiper: null as any,
      hasNewSinglePurchase: false,
      availableFilters: [] as string[],
      swiperPages: 0 as number,
      swiperIndex: 0 as number,
      filterTypes: {
        inProgress: "in-progress",
        subscriptions: "subscriptions",
        bookmarks: "bookmarks",
        assignments: "assignments",
      },
      assignmentText: "Assignments",
      scenario: "",
    };
  },
  mounted() {
    this.setAvailableFilters();
    this.setInitialFilter();
    this.getWindowSize();
    window.addEventListener("resize", this.getWindowSize);
    EventBus.on("updatesurveyCompleted", (data: boolean) => {
      this.surveyCompleted = data;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.getWindowSize);
  },
  watch: {
    myList() {
      this.setAvailableFilters();
      this.setInitialFilter();
      this.swiper?.update();
    },

    hasNewSinglePurchase(a: any) {
      if (!this.myLearningFilter.length && a) {
        this.addMyLearningFilter(this.filterTypes.subscriptions);
      }
    },
    filteredList() {
      if (this.swiper) {
        this.swiper.update();
      }
    },
    status() {
      this.setInitialFilter();
    },
    availableFilters() {
      if (this.myLearningFilter?.length) {
        this.resetFilter();
      }
    },
    slidesPerGroup() {
      this.swiper?.update();
    },
  },
  computed: {
    ...mapGetters({
      status: "user/status",
      myLearningFilter: "user/myLearningFilter",
      customerAdminRole: "user/customerAdminRole",
    }),
    slidesPerGroup(): number {
      if (this.vssWidth >= 1440) {
        return 3;
      } else if (this.vssWidth >= 896) {
        return 2;
      }
      return 1;
    },
    filteredList() {
      let list: any[] = this.myList ?? [];
      if (
        this.boxShowable &&
        this.availableFilters?.indexOf("bookmarks") == -1
      ) {
        list = [
          {
            type: "favorites-promo",
          },
          ...list,
        ];
      }
      if (this.boxShowable && !this.getUser) {
        // prepend object with type of promo
        list = [
          {
            type: "promo",
          },
          ...list,
        ];
      }

      if (this.myLearningFilter?.length) {
        list = this.filterList(list);
      }

      list = list?.filter(
        (item: any) =>
          ["promo", "favorites-promo"].includes(item.type) ||
          item.catalog_data !== undefined ||
          item.path_data !== undefined ||
          item.track_data !== undefined
      );

      // remove anything from list without a type
      list = list.filter((item: any) => item.type);
      return list;
    },
    getUser() {
      if (!this.status) return false;

      return this.status.surveyCompleted;
    },

    getUserStatusDescription() {
      let bookmark = false;

      let greetings = {
        name: "",
        descriptions: "",
      };

      this.myList?.forEach((element: any) => {
        if (element.bookmarked) {
          bookmark = true;
        }
      });

      if (this.status) {
        if (this.status.loginCount < 2) {
          greetings.name = "Welcome to Cisco U., " + this.status.display_name;
          greetings.descriptions =
            "Get started with tutorials, or scroll down for popular content.";
        } else if (!this.status.surveyCompleted) {
          if (bookmark) {
            greetings.name = "Welcome back, " + this.status.display_name;
            greetings.descriptions =
              "You're doing great! Select your interests to see content recommendations just for you.";
          } else {
            greetings.name = "Welcome to Cisco U., " + this.status.display_name;
            greetings.descriptions =
              "Scroll down for popular content, or select your interests to see recommendations just for you.";
          }
        } else {
          greetings.name = "Nice to see you, " + this.status.display_name;
          greetings.descriptions =
            "Here are content recommendations just for you. What will you learn today?";
        }
      }
      return greetings;
    },
    removeProgress_FF() {
      return window.env.REMOVE_PROGRESS_CONTENT;
    },
  },

  methods: {
    is_progressRemovable,
    ...mapMutations({
      setupToggleShowAnyway: "user/setupToggleShowAnyway",
      setMyLearningFilter: "user/setMyLearningFilter",
      addMyLearningFilter: "user/addMyLearningFilter",
    }),
    getWindowSize() {
      this.vssWidth = window.innerWidth;
    },
    setSnapIndex(swiper: any) {
      this.swiperIndex = swiper.snapIndex;
    },
    storeSwiperPaginationNum(swiper: any) {
      this.swiper?.update();
      this.swiperPages = swiper.snapGrid.length;
    },
    setAvailableFilters() {
      const filters: string[] = [];
      if (this.myList?.some((course: any) => course.percentage > 0))
        filters.push(this.filterTypes.inProgress);

      if (this.myList?.some((course: any) => course.bookmarked))
        filters.push(this.filterTypes.bookmarks);

      if (this.myList?.some((course: any) => course?.is_single))
        filters.push(this.filterTypes.subscriptions);

      if (this.myList?.some((course: any) => course?.inAssigned))
        filters.push(this.filterTypes.assignments);

      this.availableFilters = filters;
    },
    resetFilter() {
      this.myLearningFilter.forEach((filter: string) => {
        if (this.availableFilters.indexOf(filter) === -1) {
          this.setMyLearningFilter(filter);
        }
      });
    },
    setInitialFilter() {
      if (
        this.myList?.some(
          (item: any) =>
            item.is_single && item.created_at > this.status?.last_login
        )
      ) {
        this.hasNewSinglePurchase = true;
      } else if (
        this.availableFilters?.includes(this.filterTypes.inProgress) &&
        !this.myLearningFilter.length
      ) {
        this.addMyLearningFilter(this.filterTypes.inProgress);
      }
    },
    storeSwiperInstance(swiper: any) {
      this.swiper = swiper;
    },
    resultData(
      result: Result
    ):
      | Result["catalog_data"]
      | Result["path_data"]
      | Result["track_data"]
      | undefined {
      switch (result.type) {
        case "podcast":
        case "video":
        case "course":
        case "challenge":
        case "webinar":
        case "instructor-led":
        case "practice-exam":
        case "tutorial":
          if (result.catalog_data) {
            result.catalog_data.assigned_content = this.getAssignedIndex(
              result.catalog_data
            );
          }
          return result.catalog_data;
        case "path":
          if (result.path_data) {
            result.path_data.assigned_content = this.getAssignedIndex(
              result.path_data
            );
          }
          return result.path_data;
        case "track":
        case "video-track":
          if (result.track_data) {
            if (!result.track_data.type) {
              result.track_data.type = "track";
            }
            result.track_data.assigned_content = this.getAssignedIndex(
              result.track_data
            );
          }
          return result.track_data;
      }
      return undefined;
    },
    getAssignedIndex(result: any) {
      const index =
        this.filteredList?.findIndex((item) => item.guid === result.guid) + 1;
      const isAssigned =
        this.filteredList?.some(
          (item: any) => item.inAssigned && item.guid === result.guid
        ) ?? false;
      return { index: index, isAssigned: isAssigned };
    },
    filterList(list: any[]) {
      return list?.filter((item: any) => {
        const filters = this.myLearningFilter;

        if (
          filters?.indexOf(this.filterTypes.inProgress) !== -1 &&
          item.percentage > 0
        ) {
          return true;
        }

        if (
          filters?.indexOf(this.filterTypes.subscriptions) !== -1 &&
          item.is_single
        ) {
          return true;
        }
        if (
          filters?.indexOf(this.filterTypes.bookmarks) !== -1 &&
          item.bookmarked
        ) {
          return true;
        }
        if (
          filters?.indexOf(this.filterTypes.assignments) !== -1 &&
          item.inAssigned
        ) {
          return true;
        }
      });
    },
    handleFilters(filter: string) {
      if (filter == "assignments") {
        this.sendTelemetry();
      }
      this.setMyLearningFilter(filter);
    },
    returnFilterBtnType(filter: string) {
      this.updateScenario();
      // If there is only one available filter, we want to show it as a primary button
      if (this.availableFilters.length === 1) {
        return "primary";
      }

      // If the filter is not in the myLearningFilter array, it is a secondary button
      if (this.myLearningFilter?.indexOf(filter) === -1) {
        return "secondary";
      }

      // If it is in the myLearningFilter array, it is a primary button
      return "primary";
    },
    returnFilterBtnX(filter: string) {
      return this.availableFilters.length === 1 ||
        this.myLearningFilter?.indexOf(filter) !== -1
        ? "close-x"
        : null;
    },
    promoLink() {
      this.$emit("open");
      this.setupToggleShowAnyway(true);
    },
    slideTo(slide: number) {
      this.swiper.slideTo(slide);
    },
    async sendTelemetry() {
      await sendButtonCtaTelemetry(
        this.$route.fullPath,
        this.assignmentText,
        false,
        this.scenario,
        window.location.origin + "/for-you",
        "",
        "My Learning"
      );
    },
    updateScenario() {
      if (this.myLearningFilter && typeof this.myLearningFilter === "object") {
        let myLearningList = { ...this.myLearningFilter };

        if (Object.values(this.myLearningFilter).includes("assignments")) {
          this.removeByValue(myLearningList);
          this.scenario = Object.values(myLearningList).join(", ");
        } else {
          myLearningList.assignments = "assignments";
          this.scenario = Object.values(myLearningList).join(", ");
        }
      } else {
        console.error("myLearningFilter is not defined or is not an object.");
      }
    },
    isAdminContent(content: any) {
      return this.customerAdminRole && content.inAssigned && !content.is_single;
    },
    removeByValue(filterList: any) {
      Object.keys(filterList).forEach((key) => {
        if (filterList[key] === "assignments") {
          delete filterList[key];
        }
      });
    },
  },
});
</script>

<template>
  <div class="pt-16 md:pt-8">
    <div class="mx-6 xxl:mx-20 xlg:mx-auto xlg:max-w-[100rem]">
      <div class="container">
        <h1
          class="py-2.5 font-cisco text-3xl font-light text-black-dark dark:text-white-dark"
        >
          {{ getUserStatusDescription.name }}
        </h1>
      </div>

      <div class="container justify-between pb-10 md:flex">
        <div class="text-xl font-slim text-black-dark dark:text-white-dark">
          {{ getUserStatusDescription.descriptions }}
        </div>
      </div>
    </div>

    <div class="mx-6 xxl:mx-20 xlg:mx-auto xlg:max-w-[100rem]">
      <h2
        class="flex flex-wrap items-center justify-between text-18 font-bold leading-[1.2] text-black-dark dark:text-white-dark sm:text-22"
      >
        My Learning
        <router-link
          tabindex="0"
          data-cy="view-my-list"
          :to="{ name: 'my-list' }"
          class="flex flex-none cursor-pointer items-center text-14 font-light leading-[1.2] text-blue-light"
        >
          View all in My Dashboard
          <cu-icon-chevron-right class="ml-1" />
        </router-link>
      </h2>

      <div v-show="myList != null && myList.length > 0" class="mt-4">
        <div class="flex flex-wrap gap-6">
          <cu-buttons
            v-if="availableFilters?.indexOf('in-progress') !== -1"
            @click="
              availableFilters.length !== 1 && handleFilters('in-progress')
            "
            value="In progress"
            :btnType="returnFilterBtnType('in-progress')"
            :iconType="returnFilterBtnX('in-progress')"
            size="lg"
            class="whitespace-nowrap"
          ></cu-buttons>
          <cu-buttons
            v-if="availableFilters?.indexOf('subscriptions') !== -1"
            @click="handleFilters('subscriptions')"
            value="Subscriptions"
            :btnType="returnFilterBtnType('subscriptions')"
            :iconType="returnFilterBtnX('subscriptions')"
            size="lg"
          ></cu-buttons>
          <cu-buttons
            v-if="availableFilters?.indexOf('bookmarks') !== -1"
            @click="handleFilters('bookmarks')"
            value="Bookmarks"
            :btnType="returnFilterBtnType('bookmarks')"
            :iconType="returnFilterBtnX('bookmarks')"
            size="lg"
          ></cu-buttons>
          <cu-buttons
            v-if="availableFilters?.indexOf('assignments') !== -1"
            @click="handleFilters('assignments')"
            value="Assignments"
            :btnType="returnFilterBtnType('assignments')"
            :iconType="returnFilterBtnX('assignments')"
            size="lg"
          ></cu-buttons>
        </div>
      </div>
    </div>

    <div v-show="myList == null">
      <div class="mb-4 mt-4 flex xxl:mx-20 xlg:mx-auto xlg:max-w-[100rem]">
        <skeleton-loader :limit="5" />
      </div>
    </div>

    <div v-show="myList != null">
      <div class="mb-4 xxl:mx-20 xlg:mx-auto xlg:max-w-[100rem]">
        <div ref="carouselWrapper" class="flex justify-start pt-4">
          <swiper
            @swiper="storeSwiperInstance"
            @snapIndexChange="setSnapIndex"
            @snapGridLengthChange="storeSwiperPaginationNum"
            class="swiper w-full overflow-hidden"
            :center-insufficient-slides="false"
            :keyboard="{ enabled: true }"
            :grab-cursor="true"
            :modules="modules"
            :mousewheel="false"
            :passive-listeners="true"
            :observer="true"
            :observe-slide-children="true"
            :slides-per-group="slidesPerGroup"
            :slides-per-group-auto="true"
            :slides-per-view="'auto'"
          >
            <template v-for="result in filteredList" :key="result">
              <swiper-slide
                class="hide-scrollbar flex flex-1 shrink grow-0 overflow-x-visible"
              >
                <div>
                  <cu-promo-card
                    v-if="result.type === 'promo'"
                    heading="Select your interests"
                    description="Choose your interests, and Cisco U. will show content you'll love. Until then, check out what's popular."
                    btnText="Select interests"
                    @cu-promo-card="promoLink"
                  ></cu-promo-card>
                  <cu-base-card
                    v-else-if="result.type == 'favorites-promo'"
                    width="12.5rem"
                    height="16.5rem"
                    padding="2.75rem 1.5rem"
                    color="gray-0"
                    rounded="10px"
                  >
                    <h3 class="text-22 font-lighter leading-[1.2]">
                      Save your Favorites
                    </h3>
                    <div class="bm-guide mt-4 flex items-center gap-2">
                      <cu-bookmark displayOnly label="Bookmark"></cu-bookmark>
                      <cu-icon-line-right
                        class="text-gray"
                        size="l"
                      ></cu-icon-line-right>
                      <cu-bookmark displayOnly class="active"></cu-bookmark>
                    </div>
                    <p class="mt-4 font-lighter leading-[1.25]">
                      Content you bookmark or start will show up here.
                    </p>
                  </cu-base-card>
                  <the-cards
                    v-else
                    :result="resultData(result)"
                    :status="status"
                    :is-removable="
                      removeProgress_FF && is_progressRemovable(result)
                    "
                    :is-admin-content="
                      removeProgress_FF && isAdminContent(result)
                    "
                  />
                </div>
              </swiper-slide>
            </template>
          </swiper>
        </div>
      </div>
      <div
        v-if="swiperPages > 1"
        class="swiper-cu-pagination mx-6 hidden justify-end xxl:mx-20 xxl:justify-end xlg:mx-auto xlg:max-w-[100rem]"
      >
        <cu-pagination
          carousel
          :total="swiperPages"
          :current="swiperIndex + 1"
          @page-num="
            ({ detail }: any) => slideTo((detail.pageNum - 1) * slidesPerGroup)
          "
        ></cu-pagination>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto !important;
  padding-left: 1rem;
}

.swiper-slide:last-child {
  padding-right: 1rem;
}

.swiper-slide:hover {
  z-index: 1000;
}

.swiper-slide > * {
  width: 100%;
}

@media screen and (min-width: 40rem) {
  .swiper-slide:first-child {
    padding-left: 1.5rem;
  }

  .swiper-slide:last-child {
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 56rem) {
  .swiper-cu-pagination {
    display: flex;
  }
}

@media screen and (min-width: 90rem) {
  .swiper-slide {
    padding-left: 0;
    padding-right: 1rem;
  }

  .swiper-slide:first-child {
    padding-left: 0;
  }

  .swiper-slide:last-child {
    padding-right: 0;
  }
}

.bm-guide cu-bookmark::part(bookmark) {
  width: 2rem;
  height: 2rem;
}

.bm-guide cu-bookmark::part(iconSize) {
  max-width: 1rem;
  max-height: 1rem;
}
</style>
