export function is_progressRemovable(content: any) {
  const progressPercentage = Number(content.percentage ?? 0) > 0;
  if (!content || content?.is_single || content?.inAssigned) {
    return false;
  }
  return (
    ["path", "course", "tutorial"].includes(content.type) && progressPercentage
  );
}

export function urlContentTypes(contentType: string): string {
  switch (contentType) {
    case "path":
      return "paths";
    case "tutorial":
      return "tutorials";
    default:
      return contentType || "";
  }
}
