import { createHead } from "@unhead/vue";
import vClickOutside from "click-outside-vue3";
import type { App } from "vue";
import VueCookies from "vue3-cookies";

import setSessionId from "@/env/setSessionId";
import router from "@/router";
import store from "@/store/index";

await setSessionId();

// app is the Vue app instance
export default function populateApp(app: App) {
  //get existing basketId from local storage
  const basketId = localStorage.getItem("BASKET_ID");

  if (import.meta.env.PROD) {
    window?.gtagInit(
      window,
      document,
      "script",
      "dataLayer",
      window.env.GTM_ID
    );
  }

  // Clear local storage
  localStorage.clear();

  // Set the basketId in local storage
  localStorage.setItem("BASKET_ID", basketId ?? "NA");

  const head = createHead();

  app.use(router).use(VueCookies).use(store).use(vClickOutside).use(head);

  // if route is ready, mount the app
  router.isReady().then(() => {
    app.mount("#app");
  });
  return app;
}
