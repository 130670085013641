import { z } from "zod";

import http from "@/services/http";
import unauthenticatedHttp from "@/services/unauthenticatedHttp";
import isUnauth from "@/utils/isUnauth";

const metaData = z.object({
  current_page: z.number(),
  total_pages: z.number(),
  total_count: z.number(),
  pageSize: z.number(),
  previous_page_url: z.string().nullish(),
  next_page_url: z.string().nullish(),
});

const prepMaterial = z.object({
  id: z.number(),
  exam_id: z.number().nullish(),
  guid: z.string().nullish(),
  created_at: z.string().datetime().nullish(),
  updated_at: z.string().datetime().nullish(),
  deleted_at: z.string().datetime().nullish(),
  created_by: z.string().nullish(),
  updated_by: z.string().nullish(),
  title: z.string().nullish(),
  description: z.string().nullish(),
  url: z.string().nullish(),
  type: z.string().nullish(),
  SortOrder: z.number().nullish(),
});

const Topic = z.object({
  id: z.number(),
  created_at: z.string().datetime().nullish(),
  updated_at: z.string().datetime().nullish(),
  deleted_at: z.string().datetime().nullish(),
  created_by: z.string().nullish(),
  updated_by: z.string().nullish(),
  topic_name: z.string().nullish(),
  guid: z.string().nullish(),
  exam_id: z.number().nullish(),
  weight: z.number().nullish(),
});

const Exam = z.object({
  id: z.number(),
  created_at: z.string().datetime().nullish(),
  updated_at: z.string().datetime().nullish(),
  deleted_at: z.string().datetime().nullish(),
  created_by: z.string().nullish(),
  updated_by: z.string().nullish(),
  certification_id: z.number().nullish(),
  exam_number: z.string().nullish(),
  exam_id: z.number().nullish(),
  guid: z.string().nullish(),
  acronym: z.string().nullish(),
  version: z.string().nullish(),
  title: z.string().nullish(),
  release_date: z.string().datetime().nullish(),
  eol_date: z.string().datetime().nullish(),
  duration_sec: z.number().nullish(),
  summary: z.string().nullish(),
  description: z.string().nullish(),
  type: z.string().nullish(),
  topics: z.array(Topic).nullish(),
  prescribed_prep_material: z.array(prepMaterial).nullish(),
});

const Certification = z
  .object({
    id: z.number(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
    deleted_at: z.string().datetime().nullish(),
    created_by: z.string().nullish(),
    updated_by: z.string().nullish(),
    name: z.string().nullish(),
    acronym: z.string().nullish(),
    summary: z.string().nullish(),
    description: z.string().nullish(),
    active: z.number().nullish(),
    sort_order: z.number().nullish(),
    technology: z.array(z.string()).nullish(),
    certification_level: z.string().nullish(),
    ce_credits: z.number().nullish(),
    guid: z.string().nullish(),
    job_roles: z.array(z.string()).nullish(),
    skills_associated: z
      .array(z.string())
      .nullish()
      .or(z.string().default("").nullish()),
    type: z.string().nullish(),
    publisher_name: z.string().nullish(),
    language: z.string().nullish(),
    community_id: z.string().nullish(),
    community_name: z.string().nullish(),
    tag_line: z.string().nullish(),
    justification: z.string().nullish(),
    exam: z.array(Exam).nullish(),
    friendly_url: z.string().nullish().optional(),
  })
  .strict();

const certificationsResponse = z
  .object({
    data: z.array(Certification),
    metadata: metaData,
    total_records: z.number(),
    timestamp: z.string().datetime(),
    version: z.string(),
  })
  .strict();

export async function fetchById(id: any) {
  const { data } = await http().get(
    `${window.env.CERTIFICATION_API_LOCATION}/certifications/${id}?includeExams=true`
  );
  return Certification.parse(data);
}

export async function fetchAll() {
  if (isUnauth()) {
    const { data } = await unauthenticatedHttp().get(
      window.env.UNAUTH_API + "/certifications"
    );
    return data;
  } else if (!window.env.CERTIFICATION_API_LOCATION) return Promise.resolve({});
  else {
    const { data } = await http().get(
      `${window.env.CERTIFICATION_API_LOCATION}/certifications`
    );
    return certificationsResponse.parse(data);
  }
}

export async function fetchLearnCertifications() {
  if (isUnauth()) {
    const { data } = await unauthenticatedHttp().get(
      window.env.UNAUTH_API + "/learn/certifications"
    );
    return data;
  } else if (!window.env.CERTIFICATION_API_LOCATION) return Promise.resolve({});
  else {
    const { data } = await http().get(
      `${window.env.CERTIFICATION_API_LOCATION}/learn/certifications`
    );
    return certificationsResponse.parse(data);
  }
}
