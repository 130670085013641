<script lang="ts">
import "@cisco-u/base-card/cu-base-card.js";
import "@cisco-u/buttons/cu-buttons.js";
import "@cisco-u/modal/cu-modal.js";

import type { PropType } from "vue";
import { defineComponent } from "vue";

import CartProductCard from "@/components/cartProductCard.vue";
import { fetchById } from "@/services/catalogService/contentBundle";
import { validateAndAddProduct } from "@/services/commerceService/products";
import { products } from "@/services/middlewareService/productService";
import { EventBus } from "@/store/eventBus";
import currencyFormat2 from "@/utils/currencyFormat2";
import {
  allAccessList,
  essentialList,
  freeList,
} from "@/views/Subscription/lists.json";

interface ContentItem {
  title?: string;
  guid?: string;
  name?: string;
  id?: number;
}

export default defineComponent({
  components: { CartProductCard },
  props: {
    content: {
      type: Array as PropType<ContentItem[]>,
      default: () => [],
    },
    courseCard: {
      type: Boolean,
      default: false,
    },
    singleTitleBundles: {
      type: Array,
      default: () => [],
    },
    essentialsBundle: {
      type: Object,
      default: () => ({}),
    },
    allAccessBundle: {
      type: Object,
      default: () => ({}),
    },
    userStatus: {
      type: Object,
      default: () => ({}),
    },
    essentialsId: {
      type: Number,
      default: 0,
    },
    practiceExam: {
      type: Boolean,
      default: false,
    },
    inEssentialsPe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "",
      guid: "-",
      path_id: 0 as number,
      ciscoUProducts: [] as any,
      allAccess: {} as any,
      essentials: {} as any,
      loader: false,
      addToCartLoader: false,
      entitlementLoader: false,
      freeList: freeList,
      essentialList: essentialList,
      allAccessList: allAccessList,
      essentialCourses: [] as any,
      essentialExists: true,
      singleTitleContent: {} as any,
      bundleInfoFound: false,
    };
  },

  mounted() {
    this.loader = true;
    this.fetchCiscoUProductData();
    this.getSingleTitleBundles();
    this.findContent(this.essentialsId);
  },
  computed: {
    email(): string {
      return this.userStatus?.email ?? "";
    },
    essentialsBundleId(): number {
      if (!this.essentialsBundle) return 0;
      return this.essentialsBundle.content_bundle_id[0];
    },
    essentialsDiscountedPrice(): number {
      if (!this.essentialsBundle) return 0;
      return this.essentialsBundle.discount_price;
    },
    essentialsClcCount(): number {
      if (!this.essentialsBundle) return 0;
      return this.essentialsBundle.clc_count;
    },
    essentialsSubscriptionDuration(): number {
      if (!this.essentialsBundle) return 0;
      return this.essentialsBundle.subscription_length_days;
    },
    allAccessBundleId(): number {
      if (!this.allAccessBundle) return 0;
      return this.allAccessBundle.content_bundle_id[0];
    },
    allAccessDiscountedPrice(): number {
      if (!this.allAccessBundle) return 0;
      return this.allAccessBundle.discount_price;
    },
    allAccessClcCount(): number {
      if (!this.allAccessBundle) return 0;
      return this.allAccessBundle.clc_count;
    },
    allAccessSubscriptionDuration(): number {
      if (!this.allAccessBundle) return 0;
      return this.allAccessBundle.subscription_length_days;
    },
    discount(): boolean {
      return this.essentialsDiscountedPrice != 0;
    },
    discountAll(): boolean {
      return this.allAccessDiscountedPrice != 0;
    },
    discountEssentials(): string {
      return currencyFormat2(this.essentialsDiscountedPrice);
    },
    discountAllAccess(): string {
      return currencyFormat2(Number(this.allAccessDiscountedPrice));
    },
    essentialsOriginalPrice(): string {
      return currencyFormat2(Number(this.essentials.price));
    },
    allAccessOriginalPrice(): string {
      return currencyFormat2(Number(this.allAccess.price));
    },
    cartHeaderText(): string {
      if (this.practiceExam) {
        return "Get this practice exam with these Cisco U. plans.";
      }
      if (this.courseCard && this.singleTitleContent.length == 0) {
        return "This course is offered in a different plan";
      } else if (this.courseCard && this.singleTitleContent.length > 0) {
        return "The course you selected is part of these Learning Paths";
      } else {
        return "Choose the best learning for you or your team";
      }
    },
    showBlurb(): boolean {
      return this.courseCard && this.singleTitleContent.length > 0;
    },
  },
  methods: {
    formattedPrice(price: number) {
      return currencyFormat2(price);
    },
    getSingleTitleBundles() {
      const content = this.content;
      this.path_id = content[0]?.id || 0;
      let rtn: any = [];
      if (!content || content.length == 0) {
        this.singleTitleContent = rtn;
        return;
      }

      content.forEach((course: any) => {
        if (!course.guid) return;

        const stb = this.singleTitleBundles.filter((bundle: any) => {
          return bundle.guid == course.guid;
        });

        if (stb.length > 0) {
          rtn.push(stb);
          rtn = rtn.flat();
        }
      });

      this.singleTitleContent = rtn;
    },
    navigateToPlans(event: any) {
      this.closeModal(event);
      window.open(event.href, event.target);
    },
    closeModal(event: any) {
      const linkLocation = event.href;
      if (event?.detail?.state === "Close" || linkLocation.includes("path")) {
        EventBus.emit("resetModal");
      }
    },
    resetModal() {
      EventBus.emit("resetModal");
    },
    async findContent(id: any) {
      this.loader = true;
      await fetchById(id)
        .then((data) => {
          this.essentialCourses = data[0]?.contents;
          const found: boolean = this.essentialCourses?.find((content: any) => {
            if (this.content?.[0]?.guid === content.guid) {
              return true;
            }
          });
          this.essentialExists = found;
          this.loader = false;
          this.bundleInfoFound = true;
        })
        .catch(() => {
          this.loader = false;
          this.bundleInfoFound = true;
          this.$router.push({ name: "error" });
        });
    },
    async fetchCiscoUProductData() {
      this.loader = true;
      await products(
        this.allAccessBundle.productGuid,
        this.essentialsBundle.productGuid
      )
        .then((data) => {
          this.ciscoUProducts = data.ciscoUProducts;
          if (data.allAccess) {
            this.allAccess = data.allAccess;
          }
          if (data.essentials) {
            this.essentials = data.essentials;
          }
          if (this.bundleInfoFound) {
            this.loader = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    async addProduct(productId: string) {
      this.addToCartLoader = true;
      await validateAndAddProduct(productId, 1, this.email)
        .then(() => {
          this.addToCartLoader = false;
          EventBus.emit("resetModal");
          EventBus.emit("showBanner", {
            type: "SUCCESS",
            message:
              "The item has been successfully added to your shopping cart",
          });
        })
        .catch(() => {
          // intentionally empty
        });
    },
    updateCartName(name: string) {
      return this.practiceExam
        ? name.replace("Learning Path", "Practice Exam")
        : name;
    },
  },
});
</script>

<template>
  <div @modalResponse="closeModal">
    <cu-modal
      class="cdl-modal"
      ref="add-to-cart-mdl"
      type="small"
      :showLogo="true"
      logoWidth="80px"
      width="750px"
      headerHeight="231px"
      open="true"
    >
      <div class="mt-10 text-36 font-lighter" slot="header">
        <p class="mx-28" data-cy="cart-header-text">
          {{ cartHeaderText }}
        </p>
      </div>

      <div slot="body" :class="[{ 'h-[22.5rem]': loader }, 'relative']">
        <div
          v-if="loader || entitlementLoader || addToCartLoader"
          class="spinner-container"
        >
          <div>
            <div class="spinner"></div>
          </div>
        </div>
        <div v-if="!loader" class="px-5">
          <p
            data-cy="course-blurb"
            class="text-17 text-center font-lighter"
            v-if="courseCard && !practiceExam"
          >
            Courses are not sold individually. To buy this course, choose your
            favorite Learning Path.
          </p>
          <div v-if="!practiceExam">
            <div
              class="mt-10 flex flex-row flex-wrap justify-center gap-9"
              v-if="singleTitleContent.length > 2"
            >
              <cu-base-card
                v-for="(singleTitleBundle, indx) in singleTitleContent"
                :key="indx"
                width="171px"
                minHeight="252px"
                rounded="6px"
                data-cy="cisco-u-essentials-card"
              >
                <cart-product-card
                  v-if="singleTitleContent.length > 0"
                  :discount="singleTitleBundle.discount != 0"
                  :discount-price="
                    String(formattedPrice(singleTitleBundle.discount_price))
                  "
                  :price="String(formattedPrice(singleTitleBundle.price))"
                  :gui="String(singleTitleBundle.productGuid)"
                  :duration="
                    String(singleTitleBundle.subscription_length_days) +
                    '-day access'
                  "
                  :clc_count="Number(singleTitleBundle.clc_count)"
                  :acronym="String(singleTitleBundle.acronym)"
                  :display_version="String(singleTitleBundle.version)"
                  :isSingle="true"
                  :name="String(singleTitleBundle.name)"
                  @addProduct="addProduct"
                  @navigateToPlans="navigateToPlans"
                  >Individual Learning Path
                </cart-product-card>
              </cu-base-card>
            </div>
            <div
              class="mt-10 flex flex-row flex-wrap justify-center gap-9"
              v-else
            >
              <cu-base-card
                v-for="(singleTitleBundle, indx) in singleTitleContent"
                :key="indx"
                width="171px"
                minHeight="252px"
                rounded="6px"
                data-cy="cisco-u-essentials-card"
              >
                <cart-product-card
                  v-if="singleTitleContent.length > 0"
                  :discount="singleTitleBundle.discount != 0"
                  :discount-price="
                    String(formattedPrice(singleTitleBundle.discount_price))
                  "
                  :price="String(formattedPrice(singleTitleBundle.price))"
                  :gui="String(singleTitleBundle.productGuid)"
                  :duration="
                    String(singleTitleBundle.subscription_length_days) +
                    '-day access'
                  "
                  :clc_count="Number(singleTitleBundle.clc_count)"
                  :acronym="String(singleTitleBundle.acronym)"
                  :display_version="String(singleTitleBundle.version)"
                  :isSingle="true"
                  :name="String(singleTitleBundle.name)"
                  :path_id="path_id"
                  @addProduct="addProduct"
                  @navigateToPlans="navigateToPlans"
                  >Individual Learning Path
                </cart-product-card>
              </cu-base-card>
              <cu-base-card
                v-if="essentialExists"
                width="171px"
                minHeight="252px"
                rounded="6px"
                data-cy="cisco-u-essentials-card"
              >
                <cart-product-card
                  :discount="discount"
                  :discount-price="discountEssentials"
                  :price="essentialsOriginalPrice"
                  :duration="
                    String(essentialsSubscriptionDuration) + '-day access'
                  "
                  :gui="essentials.id"
                  :clc_count="essentialsClcCount"
                  :bundle-id="essentialsBundleId"
                  :name="
                    updateCartName(
                      'This Learning Path is included with a Cisco U. Essentials subscription.'
                    )
                  "
                  @addProduct="addProduct"
                  @navigateToPlans="navigateToPlans"
                  >Cisco U. Essentials
                </cart-product-card>
              </cu-base-card>
              <cu-base-card
                width="171px"
                minHeight="252px"
                rounded="6px"
                data-cy="cisco-u-all-access-card"
              >
                <cart-product-card
                  :discount="discountAll"
                  :discount-price="discountAllAccess"
                  :price="allAccessOriginalPrice"
                  :duration="
                    String(allAccessSubscriptionDuration) + '-day access'
                  "
                  :gui="allAccess.id"
                  :clc_count="allAccessClcCount"
                  :bundle-id="allAccessBundleId"
                  :name="
                    updateCartName(
                      'This Learning Path is included with a Cisco U. All Access subscription.'
                    )
                  "
                  @addProduct="addProduct"
                  @navigateToPlans="navigateToPlans"
                  >Cisco U. All Access
                </cart-product-card>
              </cu-base-card>
            </div>
          </div>
          <div
            class="mt-10 flex flex-row flex-wrap justify-center gap-9"
            v-if="singleTitleContent.length > 2 && !practiceExam"
          >
            <cu-base-card
              v-if="essentialExists"
              width="171px"
              minHeight="252px"
              rounded="6px"
              data-cy="cisco-u-essentials-card"
            >
              <cart-product-card
                :discount="discount"
                :discount-price="discountEssentials"
                :price="essentialsOriginalPrice"
                :duration="
                  String(essentialsSubscriptionDuration) + '-day access'
                "
                :gui="essentials.id"
                :clc_count="essentialsClcCount"
                :bundle-id="essentialsBundleId"
                :name="
                  updateCartName(
                    'This Learning Path is included with a Cisco U. Essentials subscription.'
                  )
                "
                @addProduct="addProduct"
                @navigateToPlans="navigateToPlans"
                >Cisco U. Essentials
              </cart-product-card>
            </cu-base-card>
            <cu-base-card
              v-if="!courseCard"
              width="171px"
              minHeight="252px"
              rounded="6px"
              data-cy="cisco-u-all-access-card"
            >
              <cart-product-card
                :discount="discountAll"
                :discount-price="discountAllAccess"
                :price="allAccessOriginalPrice"
                :duration="
                  String(allAccessSubscriptionDuration) + '-day access'
                "
                :gui="allAccess.id"
                :clc_count="allAccessClcCount"
                :bundle-id="allAccessBundleId"
                :name="
                  updateCartName(
                    'This Learning Path is included with a Cisco U. All Access subscription.'
                  )
                "
                @addProduct="addProduct"
                @navigateToPlans="navigateToPlans"
                >Cisco U. All Access</cart-product-card
              >
            </cu-base-card>
          </div>
          <div
            class="mt-10 flex flex-row flex-wrap justify-center gap-9"
            v-if="practiceExam"
          >
            <cu-base-card
              v-if="inEssentialsPe"
              width="171px"
              minHeight="252px"
              rounded="6px"
              data-cy="cisco-u-essentials-card"
            >
              <cart-product-card
                :discount="discount"
                :discount-price="discountEssentials"
                :price="essentialsOriginalPrice"
                :duration="
                  String(essentialsSubscriptionDuration) + '-day access'
                "
                :gui="essentials.id"
                :clc_count="essentialsClcCount"
                :bundle-id="essentialsBundleId"
                :name="
                  updateCartName(
                    'This Learning Path is included with a Cisco U. Essentials subscription.'
                  )
                "
                @addProduct="addProduct"
                @navigateToPlans="navigateToPlans"
                >Cisco U. Essentials
              </cart-product-card>
            </cu-base-card>
            <cu-base-card
              width="171px"
              minHeight="252px"
              rounded="6px"
              data-cy="cisco-u-all-access-card"
            >
              <cart-product-card
                :discount="discountAll"
                :discount-price="discountAllAccess"
                :price="allAccessOriginalPrice"
                :duration="
                  String(allAccessSubscriptionDuration) + '-day access'
                "
                :gui="allAccess.id"
                :clc_count="allAccessClcCount"
                :bundle-id="allAccessBundleId"
                :name="
                  updateCartName(
                    'This Learning Path is included with a Cisco U. All Access subscription.'
                  )
                "
                @addProduct="addProduct"
                @navigateToPlans="navigateToPlans"
                >Cisco U. All Access</cart-product-card
              >
            </cu-base-card>
          </div>
        </div>
        <p
          class="text-17 mt-10 text-center font-lighter"
          v-if="!practiceExam && courseCard && singleTitleContent.length > 0"
        >
          For Cisco U. subscriptions,
          <router-link
            :to="{ name: 'subscription' }"
            class="underline decoration-dotted underline-offset-4"
            @click.prevent="resetModal()"
            @keyup.enter="resetModal()"
          >
            visit View Plans
          </router-link>
        </p>
        <p
          class="text-17 mt-10 text-center font-lighter"
          v-if="practiceExam && courseCard && singleTitleContent.length > 0"
        >
          To compare Cisco U. subscriptions,
          <router-link
            :to="{ name: 'subscription' }"
            class="underline decoration-dotted underline-offset-4"
            @click.prevent="resetModal()"
            @keyup.enter="resetModal()"
          >
            visit View Plans
          </router-link>
        </p>
        <p
          class="text-17 mt-10 text-center font-lighter"
          v-if="!practiceExam && courseCard && singleTitleContent.length == 0"
        >
          <router-link
            :to="{ name: 'subscription' }"
            class="underline decoration-dotted underline-offset-4"
            @click.prevent="resetModal()"
            @keyup.enter="resetModal()"
          >
            Explore our Cisco U. plans
          </router-link>
          to find the best option
        </p>
        <div
          v-if="courseCard && singleTitleContent.length == 0"
          class="mt-12 flex"
        >
          <cu-buttons
            data-cy="reset-modal"
            @click.prevent="resetModal()"
            @keyup.enter="resetModal()"
            class="mx-auto mt-3.5 block text-12"
            btnType="primary"
            value="OK"
            width="105.72px"
            size="lg"
            tabindex="0"
          />
        </div>
      </div>
    </cu-modal>
  </div>
</template>
