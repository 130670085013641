<script lang="ts">
import { defineComponent } from "vue";

import CertCard from "@/components/contentBits/certCard.vue";
import challengeResult from "@/components/contentBits/challengeResult.vue";
import EventResult from "@/components/contentBits/eventsResult.vue";
import instructorLedResult from "@/components/contentBits/instructorLedResult.vue";
import LearningPathResult from "@/components/contentBits/learningPathResult.vue";
import PodCastCard from "@/components/contentBits/podCastCard.vue";
import practiceExamResult from "@/components/contentBits/practiceExamResult.vue";
import TutorialsResult from "@/components/contentBits/tutorialsResult.vue";
import VideosCard from "@/components/contentBits/videosCard.vue";
import typeMap from "@/components/typeMap.json";

export default defineComponent({
  components: {
    CertCard,
    challengeResult,
    EventResult,
    instructorLedResult,
    LearningPathResult,
    PodCastCard,
    practiceExamResult,
    TutorialsResult,
    VideosCard,
  },
  props: {
    result: {
      type: Object,
      default: null,
    },
    status: {
      type: Object,
      default: null,
    },
    freeContent: {
      type: Boolean,
      default: false,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    topicView: {
      type: Boolean,
      default: false,
    },
    isAdminContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentType(): string {
      // it could be catalog_data.type, path_data.type, track_data.type, or just type
      const resultType =
        this.result?.catalog_data?.type ??
        this.result?.path_data?.type ??
        this.result?.track_data?.type ??
        this.result?.type;
      return typeMap[resultType as keyof typeof typeMap] ?? "";
    },
  },
});
</script>

<template>
  <component
    :is="componentType"
    :result="result"
    :status="status"
    :cardView="cardView"
    :free-content="freeContent"
    :is-removable="isRemovable"
    :topic-view="topicView"
    :is-admin-content="isAdminContent"
  />
</template>
